import React, { ReactElement } from "react"
import DotPattern from "../images/pattern-dots.svg"

const Footer = (): ReactElement => {
  return (
    <div className="container">
      <div className="lg:w-10/12 mx-auto py-10 2xl:py-20 2xl:w-full">
        <div className="eyebrow">Let’s get started</div>
        <div className="lg:flex md:justify-between">
          <div className="lg:w-10/12">
            <h2 className="heading text-charcoal text-3xl md:text-4xl lg:text-6xl uppercase">
              Interested in working together?{" "}
            </h2>
          </div>
          <div className="hidden lg:block relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="117.3"
              height="67.4"
              overflow="visible"
              viewBox="0 0 117.3 67.4"
              className="fill-current text-teal-700 z-10 relative"
            >
              <path d="M83 0H60l25.4 25.5H0v16.4h86.6L61.1 67.4h23.1l33.1-33.1z" />
            </svg>
            <div
              className="contact-pattern"
              style={{ backgroundImage: `url(${DotPattern})` }}
            />
          </div>
        </div>
      </div>
      <div className="lg:w-10/12 mx-auto 2xl:w-full text-red font-utopia text-xl md:text-3xl py-10 2xl:py-20 border-t border-grey-700">
        <a className="hover-link" href="mailto:justinpmartin@gmail.com">
          justinpmartin@gmail.com
        </a>{" "}
        /{" "}
        <a className="hover-link whitespace-nowrap" href="tel:1-403-701-4255">
          1 (403) 701-4255
        </a>
      </div>
    </div>
  )
}
export default Footer
